import { required, maxLength } from "vuelidate/lib/validators";
import { onlyAlphanumeric } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        TpClientNameEn: { required, onlyAlphanumeric, maxLength: maxLength(250) },
        TpClientNameEs: { required, onlyAlphanumeric, maxLength: maxLength(250) },
    }
}

