var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.LoadingGlobal,"is-full-page":true,"loader":"bars"}}),_c('CCollapse',{attrs:{"show":_vm.collapse}},[_c('CCard',[_c('CCardHeader',{attrs:{"color":"dark text-white"}},[_c('CRow',[_c('CCol',{staticClass:"d-flex",attrs:{"sm":"8"}},[_vm._v(_vm._s(_vm.titulo))]),_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"4"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.dataTable'),
                  placement: 'top-end'
              }),expression:"{\n                  content: $t('label.dataTable'),\n                  placement: 'top-end'\n              }"}],attrs:{"size":"sm"},on:{"click":function($event){_vm.tablaCollapse = !_vm.tablaCollapse}}},[_vm._v(" "+_vm._s(_vm.cambiaTexto)+" "),_c('CIcon',{attrs:{"name":_vm.cambiaIcono}})],1)],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{staticClass:"text-right mt-2 pr-1",attrs:{"sm":"3"}},[_c('label',[_c('b',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v(" "+_vm._s(_vm.$t('label.activity')+' '+('(ES)')))])]),_c('CCol',{attrs:{"sm":"7"}},[_c('CInput',{attrs:{"maxlength":"250","is-valid":_vm.hasError(_vm.$v.TpClientNameEs),"invalid-feedback":_vm.errorMessage(_vm.$v.TpClientNameEs),"placeholder":_vm.$t('label.activityName')+' '+('(ES)')},model:{value:(_vm.$v.TpClientNameEs.$model),callback:function ($$v) {_vm.$set(_vm.$v.TpClientNameEs, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.TpClientNameEs.$model"}})],1),_c('CCol',{staticClass:"text-right mt-2 pr-1",attrs:{"sm":"3"}},[_c('label',[_c('b',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v(" "+_vm._s(_vm.$t('label.activity')+' '+('(EN)'))+" ")])]),_c('CCol',{attrs:{"sm":"7"}},[_c('CInput',{attrs:{"maxlength":"250","is-valid":_vm.hasError(_vm.$v.TpClientNameEn),"invalid-feedback":_vm.errorMessage(_vm.$v.TpClientNameEn),"placeholder":_vm.$t('label.activityName')+' '+('(EN)')},model:{value:(_vm.$v.TpClientNameEn.$model),callback:function ($$v) {_vm.$set(_vm.$v.TpClientNameEn, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.TpClientNameEn.$model"}})],1),_c('CCol',{attrs:{"sm":"2"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.add'),
                        placement: 'top-end'
                    }),expression:"{\n                        content: $t('label.add'),\n                        placement: 'top-end'\n                    }"}],staticClass:"mr-1",attrs:{"color":"add","disabled":_vm.isSubmit,"size":"sm"},on:{"click":function($event){return _vm.verifyStatus()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.clearFields'),
                        placement: 'top-end'
                    }),expression:"{\n                        content: $t('label.clearFields'),\n                        placement: 'top-end'\n                    }"}],staticClass:"mr-1",attrs:{"color":"wipe","disabled":_vm.isSubmit,"size":"sm"},on:{"click":function($event){return _vm.refreshComponent()}}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1),(_vm.isEdit)?_c('CCol',{staticClass:"text-right mt-2 pr-1",attrs:{"sm":"3"}},[_c('label',[_c('b',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v(" "+_vm._s(_vm.$t('label.status'))+" ")])]):_vm._e(),(_vm.isEdit)?_c('CCol',{attrs:{"sm":"7"}},[_c('CSelect',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"value":_vm.Status,"is-valid":_vm.statusSelectColor,"options":_vm.selectOptions},on:{"update:value":function($event){_vm.Status=$event}},model:{value:(_vm.Status),callback:function ($$v) {_vm.Status=$$v},expression:"Status"}})],1):_vm._e()],1)],1)],1),_c('CCollapse',{staticClass:"mt-2",attrs:{"show":_vm.tablaCollapse}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.items,"fields":_vm.fields,"loading":_vm.Loading,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":5,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.activity'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.activity'),\n                  placement: 'top-end'\n                }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }